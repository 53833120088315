.searchBar {
  margin-top: 12px;
  padding: 15px;
  display: flex;
}

.searchBar__input {
  @include placeholder-color(#495057);
  background-color: #e9ecef;
  color: #495057;
  width: 100%;
  height: 2.2rem;
  line-height: 2.2rem;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  border: 1px solid #e9ecef;
  //border-radius: 0.25rem;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  &:focus{
    outline: none;
  }
}

.searchBar__img {
  background-color: #e9ecef;
    display: flex;
    align-items: center;
    /* padding: 0 1rem; */
    border-radius: 25px 0 0 25px;
    padding-left: 1rem;
    padding-right: 0.5rem;
    border: 1px solid #e9ecef;
    margin-right: -1px;
    svg{
      color: #ccc;
    }
}

.search__bar__in__bar{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  @media (min-width: 910px){
    display: block;
    left: 10rem;
    max-width: 200px;
  }
  @include media-breakpoint-up(xl) { // 1200px and up
    max-width: 300px;
    left: 9rem;
  }
  .searchBar{
    padding: 0;
    margin-top: 0px;
  }
  svg{
    color: #ccc;
  }
}