.banner-item {
  max-width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  // &.bannerHasTitlePanel::before {
  //   content: '';
  //   background: rgba(0,0,0,0.3333);
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   z-index: 5;
  // }

}

.banner_item__text {
  text-align: center;
  padding: 0.5rem 0 0.7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1200px;
  z-index: 10;
  font-family: $family-header;
  font-weight: 600;
  color: #fff;
  //font-size: 1.6rem;
  font-size: 2.5rem;
  //line-height: 1.1;
  line-height: 1;

  @include media-breakpoint-up(md){
    font-size: 5rem;
  }
  
  @include media-breakpoint-up(lg) {
    //font-size: 2.3rem;
    font-size: 5.2rem;
    //margin-bottom: -0.3rem;
  }
}

.banner-item__link {
  font-size: 1rem; 
  font-weight: 400;
  display: inline-block;
  padding: 0.4rem 1rem;
  background-color: $primary-color;
  color: #fff;
  margin-top: 1rem;

  @include media-breakpoint-up(lg) {
  }

  &:hover, &:focus {
    color: #fff; 
    background-color: $link-hover-color;
    text-decoration: none;
  }
}

.banner-item__subTitle{
  font-size: 1.3rem; 
  //font-weight: 400;
  //display: inline-block;
  //padding: 0.4rem 1rem;
  color: #fff;
  margin-bottom: -0.3rem;

  @include media-breakpoint-up(md){
    font-size: 2rem;
    margin-bottom: -0.8rem;
  }

  @include media-breakpoint-up(lg){
    font-size: 2.5rem;
    margin-bottom: -0.8rem;
  }
}

.bannerItem__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}