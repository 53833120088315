@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import "~aos/src/sass/aos";

@media print {
  @import './print';
}

@keyframes fontbulger {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.spinner_loading{
  animation: fontbulger 2s infinite;
}

// div.col__table__style{
//   border: 1px solid;
//   padding: 10px 5px;
//   @include media-breakpoint-down(xs){
//     font-size: 0.7rem;
//   }
// }